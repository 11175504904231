(function (module) {
    var rrRequestCtrl = function ($state, $timeout, alertSvc, collegeVerificationTypes, organizationSvc, helperSvc, codeSvc, readinessSvc, eventSvc, typeConstSvc, commissionTypes, READINESS_REVIEW_DATES, environmentSvc) {

        var model = this;
        model.title = 'Request for Readiness Review';
        model.isSubmitted = false;

        var isReadinessCycle = true;
        model.academicYear = helperSvc.getAcademicYear(isReadinessCycle);
        model.reviewYearSpan = helperSvc.getYearSpan(model.academicYear + 2);
        model.reviewNextYearSpan = helperSvc.getYearSpan(model.academicYear + 3);
        model.readinessStartDate = new Date(model.academicYear + 1, READINESS_REVIEW_DATES.START.getMonth(), READINESS_REVIEW_DATES.START.getDate());
        model.readinessEndDate = new Date(model.academicYear, READINESS_REVIEW_DATES.END.getMonth(), READINESS_REVIEW_DATES.END.getDate())

        var READINESS_END_DATE_ADDITIONAL_DAYS = 2;  //app closes after 2 days of the official end date (10/1)
        model.readinessAppClosingDate = new Date(model.academicYear, READINESS_REVIEW_DATES.END.getMonth(), READINESS_REVIEW_DATES.END.getDate() + READINESS_END_DATE_ADDITIONAL_DAYS, 23, 59, 59, 999);

        model.isClosed = !environmentSvc.isTestMode() && (new Date().getTime() > model.readinessAppClosingDate.getTime()) && (new Date().getTime() < model.readinessStartDate.getTime());

        model.back = function () {
            $state.go('root');
        };

        model.programs = [];
        model.namePrefixes = [];
        model.commissions = [];
        model.showCommissionWarning = false;

        // Institution Select

        model.addingNewInstitution = false;
        model.isInstitutionSelected = false;

        model.addNewInstitution = function () {
            model.addingNewInstitution = true;
            model.rr.organizationJson = {};
            model.rr.organizationJson.organizationRecognition = [];
            model.rr.organizationJson.countryCode = 'US';
            model.onCountryCodeChange();
            model.agencies = [];
            model.addAgency();
        };

        model.onInstitutionSelect = function (programs, recognizingAgencies) {
            model.isInstitutionSelected = true;
            model.hasRecognitionAgency = false;

            if (recognizingAgencies && recognizingAgencies.length > 0) {
                model.hasRecognitionAgency = true;
                
            } else {
                model.onCountryCodeChange();
                model.agencies = [];
                model.addAgency();
            }

            if (programs && programs.length > 0) {
                model.showCommissionWarning = true;
                model.commissionWarning = getWarningMsg();

                function getCommissionsWithAcceptedPrograms () {
                    var commissionNames = [];

                    programs.forEach(function (program) {

                        commissionTypes.forEach(function (commission) {

                            if (commission.id == program.commissionId && program.accreditationDates != null) {
                                var name = commission.name + ' (' + commission.abrv + ')';
                                if (!helperSvc.arrContains(commissionNames, name)) {
                                    commissionNames.push(name);
                                }
                            }
                        })
                    });
                    return commissionNames;
                }

                function getWarningMsg () {
                    var commissionNames = getCommissionsWithAcceptedPrograms();
                    var msg = "<p>This request should be to add new programs for a new commission. Currently this institution has programs accredited with ABET in ";
                    var commissions = "<em>" + commissionNames + ".</em></p>";

                    if (commissionNames.length < 1) return null;
                    if (commissionNames.length > 1) {
                        commissions = "the following commissions...</p><ul>";
                        commissionNames.forEach(function (commission) {
                            commissions += "<li><em>" + commission + "</em></li>";
                        });
                        commissions += "</ul>";
                    }

                    return msg + commissions;
                }
            }
        };

        model.newSearch = function () {
            model.rr.organizationJson = null;
            model.addingNewInstitution = false;
            model.isInstitutionSelected = false;
            model.showCommissionWarning = false;
        };

        // Agency Search

        //model.searchAgency = function (val) {
        //    if (val.length < 3)
        //        return;

        //    return organizationSvc.searchRecognizingAgencies(val).then(function (data) {
        //        return data.value;
        //    });
        //};
                
        model.recognitionData = [];
        model.selectedRecognitionList = null;
        model.agencies = [];
                
        organizationSvc.quickSearchUS('').then(function (data) {
            model.recognitionData.usList = data.value;
        });

        organizationSvc.quickSearchNonUS('').then(function (data) {
            model.recognitionData.nonUSList = data.value;
            model.recognitionData.nonUSList.push({ organizationId: "OTHER", organizationName: "Other", sortName: "zz" });
        });

        model.addAgency = function () {
            var newAgency = {
                data: null,
                isDeleted: false
            };
            model.agencies.push(newAgency);
        };

        model.deleteAgency = function (agency) {
            agency.isDeleted = true;
        };

        var removeDeletedAgencies = function () {
            var submittedAgencies = [];

            model.agencies.forEach(function (agency) {
                if (!agency.isDeleted) submittedAgencies.push(agency);
            });

            model.agencies = submittedAgencies;
        };

        var copyAgenciesToRR = function () {
            if (model.isInstitutionSelected && model.hasRecognitionAgency) return;
            var recognitionAgencies = [];

            model.agencies.forEach(function (agency) {
                var orgId, orgName;

                if (agency.data.organizationId === 'OTHER') {
                    orgId = 0;
                    orgName = agency.writeIn
                } else {
                    orgId = agency.data.organizationId;
                    orgName = agency.data.organizationName
                }

                var newAgency = {
                    recognitionOrganizationId: orgId,
                    organizationName: orgName
                };

                recognitionAgencies.push(newAgency);
            });
            model.rr.organizationJson.organizationRecognition = recognitionAgencies;
        };

        model.onCountryCodeChange = function () {
            model.agencies = [];
            model.addAgency();

            if (model.rr.organizationJson.countryCode === 'US') {
                model.rr.organizationJson.province = null;
                model.selectedRecognitionList = 'usList';
            } else {
                model.rr.organizationJson.stateCode = null;
                model.selectedRecognitionList = 'nonUSList';
            }
        };

        codeSvc.getCountries().then(function (data) {
            model.countryList = data.value;
        });

        codeSvc.getStates().then(function (data) {
            model.stateList = data.value;
        });

        // Programs

        model.addProgram = function () {
            var newProgram = {
                programName: null,
                commissionIds: null,
                degreeCode: null,
                isDeleted: false,
                readinessReview: {}
            };

            model.rr.programs.push(newProgram);
        };

        model.deleteProgram = function (program) {
            program.isDeleted = true;
        };

        var removeDeletedPrograms = function () {
            var submittedPrograms = [];

            model.rr.programs.forEach(function (program) {
                if (!program.isDeleted) submittedPrograms.push(program);
                //delete program.isDeleted;
            });

            model.rr.programs = submittedPrograms;
        };

        var getNoteFromInstitutionObj = function () {
            if (model.rr.organizationJson.systemNote) model.rr.systemNote = model.rr.organizationJson.systemNote;
            delete model.rr.organizationJson.systemNote;
        };

        model.onCommissionSelected = readinessSvc.setCommission;

        model.submit = function () {
            removeDeletedPrograms();
            removeDeletedAgencies();
            copyAgenciesToRR();
            readinessSvc.create(model.rr).then(function (data) {
                model.isSubmitted = true;
                window.scroll(0, 0);
                reset();
                eventSvc.broadcast('resetInstitutionSearch');
            });
        };

        function reset() {
            model.rr = {
                organizationId: null,
                organizationJson: null,
                contactPersonId: null,
                contactPersonJson: null,
                programs: [],
                note: null,
                systemNote: null
            };
            model.programs = [];
            model.addProgram();
        }

        var activate = function () {
            reset();

            codeSvc.getNamePrefixes().then(function (data) {
                model.namePrefixes = data.value.map(function (obj) { return obj.codeKey });
            });

            model.commissions = typeConstSvc.getCommissionWithJointTypes();
        }();
    };

    module.controller('rrRequestCtrl', rrRequestCtrl);

}(angular.module('readinessReview')));